import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

//  Components
import Footer from "common/footer";
import TextBanner from "common/textBanner";
import Header from "common/header/header";
import ListingProducts from "features/shop/layouts/listingProducts";

function ShopCategories() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="">
      <div>
        <Header />
        <TextBanner text={location?.state?.name} />
        <ListingProducts />
      </div>
      <Footer />
    </div>
  );
}

export default ShopCategories;
