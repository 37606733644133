// actions.js
import {
  locationTriggered,
  locationsReceived,
  locationFailure,
  paginationReceived,
} from "./locationSlices";

import { GET_LOCATIONS } from "./locationAPI";

export const loadLocations = (params, callback) => async (dispatch) => {
  try {
    dispatch(locationTriggered());
    const response = await GET_LOCATIONS(params);
    if (response.status === 200) {
      dispatch(locationsReceived(response.data.data));
      dispatch(paginationReceived(response.data.pagination));
    } else {
      dispatch(locationFailure(response.data));
    }
    callback(response.data);
  } catch (error) {
    dispatch(locationFailure(error.response));
  }
};
