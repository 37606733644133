// userSlice.js
import { createSlice } from "@reduxjs/toolkit";

const bookingSlice = createSlice({
  name: "booking",
  initialState: {
    bookings: [],
    loading: false,
    loadingVendors: false,
    errors: null,
    pagination: null,
    bookingDetails: null,
    bookingCount: null,
    vendors: null,
  },
  reducers: {
    bookingTriggered: (state, action) => {
      state.loading = true;
    },
    vendorTriggered: (state, action) => {
      state.loadingVendors = true;
    },
    bookingsReceived: (state, action) => {
      state.bookings = action.payload;
      state.loading = false;
    },
    bookingDetailsReceived: (state, action) => {
      state.bookingDetails = action.payload;
      state.loading = false;
    },
    bookingCountReceived: (state, action) => {
      state.bookingCount = action.payload;
    },
    vendorsReceived: (state, action) => {
      state.loadingVendors = action.payload;
    },
    paginationReceived: (state, action) => {
      state.pagination = action.payload;
    },
    vendorFailure: (state, action) => {
      state.errors = action.payload;
      state.loadingVendors = false;
    },
    bookingFailure: (state, action) => {
      state.errors = action.payload;
      state.loading = false;
    },
  },
});

export const {
  bookingTriggered,
  bookingsReceived,
  bookingFailure,
  paginationReceived,
  bookingDetailsReceived,
  bookingCountReceived,
  vendorsReceived,
  vendorTriggered,
  vendorFailure,
} = bookingSlice.actions;
export default bookingSlice.reducer;
