import React, { useState } from "react";
import { useFormik, useFormikContext } from "formik";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
// import { firebase, auth } from '../../../utils/firebase';
import { auth } from "utils/firebase.config";
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth'

//  Redux & Validations
import { loginSchema, verifyNumberSchema } from "utils/schema";
import { logIn, verifyPhoneNumber } from "store/auth/authActions";
import { connect } from "react-redux";
//  Components
import Button from "common/Button";
import TextInput from "common/TextInput";
import Modal from "common/customModal"
const Login = ({ initialValues = null, logIn, getUser, setMobile, verifyPhoneNumber }) => {
  const location = useLocation();
  // console.log("auth", auth)
  // console.log("firebase", firebase)
  const [showPass, setShowPass] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [otp, setOtp] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const phoneStartValues = {
    mobile: initialValues?.mobile ?? "+91",
  };

  const numberVerify = useFormik({
    initialValues: phoneStartValues,
    enableReinitialize: true,
    validationSchema: verifyNumberSchema,
    onSubmit: async (values, helpers) => {
      setLoading(true);

      verifyPhoneNumber(values?.mobile, (res) => {
        if (res.data.isMobileExist === false) {
          onCaptchaVerify();
          const appVerifier = window.recaptchaVerifier;
          signInWithPhoneNumber(auth, values?.mobile, appVerifier)
            .then((confirmationResult) => {
              toast.success("OTP Sent to Your Phone")
              setLoading(false);
              setModalShow(true)
              window.confirmationResult = confirmationResult;
            }).catch((error) => {
              toast.error("Something went wrong, Please try again")
              setLoading(false);
              // console.log("ERROR", error)
            });
        }
        else {
          setLoading(false);
          setShowLoginForm(true);
        }
      })
    },
  });
  const startValues = {
    name: numberVerify.values?.name ?? "",
    mobile: numberVerify?.values?.mobile ?? "",
  };

  const formik = useFormik({
    initialValues: startValues,
    enableReinitialize: true,
    validationSchema: loginSchema,
    onSubmit: async (values, helpers) => {
      setLoading(true);
      // console.log("values", values)
      setLoading(false);

      const payLoad = {
        mobile: values?.mobile,
        password: values?.password,
      };
      logIn(payLoad, (res) => {
        if (res?.code === 200) {
          setLoading(false);
          toast.success("Login Successful!");
          localStorage.setItem("user", JSON.stringify(res?.data));
        } else {
          toast.error(res?.message);
          setLoading(false);
        }
      });
    },
  });

  const onCaptchaVerify = () => {

    const recaptchaContainer = document.getElementById('recaptcha-container');
    if (!window.recaptchaVerifier) {

      window.recaptchaVerifier = new RecaptchaVerifier(auth, recaptchaContainer, {
        'size': 'invisible',
        'callback': (response) => {
        }
      }, auth);
    }


  }
  function onOTPVerify() {
    setModalShow(false);
    window.confirmationResult.confirm(otp).then(async (res) => {
      toast.success("Your Phone Number Verified")
      setShowLoginForm(true);
      // console.log("OTP SUBMITTED", res);
    }).catch((error) => {
      // console.log("error", error);
    })
  }
  const isLoggedIn = localStorage.getItem("user-auth-x-token");
  return (
    <div class="returning_customer">
      <div class="check_title">
        <h2>Login / Register</h2>
      </div>
      <p>
        If you've previously booked with us, please provide your mobile number
        and password to log in. If you're new to our service, you can create a
        new account by providing your mobile number, Entering the OTP received on your phone and selecting a password.
      </p>
      {showLoginForm ? <form
        class="row contact_form"
        action="#"
        method="post"
        novalidate="novalidate"
        onSubmit={formik.handleSubmit}
      >
        <div class="col-md-6 form-group p_star">
          <TextInput
            type="text"
            placeholder="Mobile"
            name="mobile"
            // maxLength="10"
            isInvalid={!!(formik.touched.mobile && formik.errors.mobile)}
            onBlur={formik.handleBlur}
            onChange={(e) => {
              if (isNaN(e.target.value)) return;
              setMobile(e.target.value);
              formik.handleChange(e);
            }}
            disabled={isLoggedIn}
            value={formik.values.mobile}
            error={formik.touched.mobile ? formik.errors.mobile : ""}
          />
        </div>
        {showLoginForm && <div class="col-md-6 form-group p_star">
          <TextInput
            type={showPass ? "text" : "password"}
            placeholder="Password"
            name="password"
            isInvalid={!!(formik.touched.password && formik.errors.password)}
            onBlur={formik.handleBlur}
            onChange={(e) => {
              formik.handleChange(e);
            }}
            disabled={isLoggedIn}
            iconClick={(e) => setShowPass((prev) => !prev)}
            icon={showPass ? "fa-solid fa-eye-slash" : "fa-solid fa-eye"}
            value={formik.values.password}
            error={formik.touched.password ? formik.errors.password : ""}
          />
        </div>}
        {!isLoggedIn && (
          <div class="col-md-12 form-group">
            <Button loading={loading} disabled={isLoggedIn} text="LOGIN" />

          </div>
        )}
      </form> : (
        <form
          class="row contact_form"
          action="#"
          method="post"
          novalidate="novalidate"
          onSubmit={numberVerify.handleSubmit}
        >
          <div class="col-md-6 form-group p_star">
            <TextInput
              type="text"
              placeholder="Mobile"
              name="mobile"
              // maxLength="10"
              isInvalid={!!(numberVerify.touched.mobile && numberVerify.errors.mobile)}
              onBlur={numberVerify.handleBlur}
              onChange={(e) => {
                if (isNaN(e.target.value)) return;
                setMobile(e.target.value);
                numberVerify.handleChange(e);
              }}
              disabled={isLoggedIn}
              value={numberVerify.values.mobile}
              error={numberVerify.touched.mobile ? numberVerify.errors.mobile : ""}
            />
            <div id="recaptcha-container"></div>

          </div>
          {!isLoggedIn && (
            <div class="col-md-12 form-group">
              <Button loading={loading} disabled={isLoggedIn} text="Verify Phone Number"></Button>
            </div>)}
        </form>
      )}

      <Modal
        setOtp={setOtp}
        otp={otp}
        show={modalShow}
        onHide={() => setModalShow(false)}
        onOTPVerify={onOTPVerify}
      >

      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  getUser: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  logIn: (data, callback) => dispatch(logIn(data, callback)),
  verifyPhoneNumber: (data, callback) => dispatch(verifyPhoneNumber(data, callback))
});
export default connect(mapStateToProps, mapDispatchToProps)(Login);
