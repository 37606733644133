import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import waveIcon from "include/Images/icon-zigzag.png";

import { useParallax } from "react-scroll-parallax";
import Button from "./Button";

const Slider = () => {
  const options = {
    items: 1,
    // autoplay: false,
    // autoplayTimeout: 5000,
    // loop: true,
    // nav: true,
    // navText: [
    //     "<img src='img/banner/prev.png'>",
    //     "<img src='img/banner/next.png'>"
    // ],
    dots: false,
  };
  return (
    <div className="col-lg-12">
      <OwlCarousel className="owl-theme active-banner-slider " {...options}>
        <div class="item">
          <div class="row single-slide align-items-center d-flex">
            <div class=" hero_content d-flex flex-column align-items-center justify-content-center">
              <img src={waveIcon} alt="" />
              <h4>Welcome to</h4>
              <h1 className="text-center text-white">
                Goa Water Sports Association
              </h1>
              <a href="#features" className="hero_btn">
                Book Now
              </a>
            </div>
          </div>
        </div>
      </OwlCarousel>
    </div>
  );
};

export default Slider;
