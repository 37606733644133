import React, { useEffect } from "react";
import ConfirmationInfo from "./layouts/confirmationInfo";
import TextBanner from "common/textBanner";
import Footer from "common/footer";
import Header from "common/header/header";
import BookingDetails from "./layouts/bookingDetails";
const ShoppingConfirmation = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      <TextBanner
        text="Order Confirmed"
        subText="Your Order was placed successfully"
      />
      <ConfirmationInfo />
      <BookingDetails />
      <Footer />
    </>
  );
};

export default ShoppingConfirmation;
