// actions.js
import {
  serviceTriggered,
  servicesReceived,
  serviceFailure,
  paginationReceived,
} from "./serviceSlices";

import { GET_SERVICES, DELETE_SERVICE, ADD_SERVICE, UPDATE_SERVICE } from "./serviceAPI";

export const loadServices = (_id, params, callback) => async (dispatch) => {
  try {
    dispatch(serviceTriggered());
    const response = await GET_SERVICES(_id, params);
    if (response.status === 200) {
      dispatch(servicesReceived(response.data.data));
      dispatch(paginationReceived(response.data.pagination));
    } else {
      dispatch(serviceFailure(response.data));
    }
    callback(response.data);
  } catch (error) {
    dispatch(serviceFailure(error.response));
  }
};

export const deleteService = (id, callback) => async (dispatch) => {
  try {
    const response = await DELETE_SERVICE(id);
    if (response.status === 200) {
      callback(response.data);
    } else {
      callback(response.response?.data);
    }
  } catch (error) {
    dispatch(serviceFailure(error.response));
  }
};

export const addService = (data, callback) => async (dispatch) => {
  try {
    const response = await ADD_SERVICE(data);
    if (response.status === 200) {
      callback(response.data);
    } else {
      callback(response.response?.data);
    }
  } catch (error) {
    dispatch(serviceFailure(error.response));
  }
};

export const updateService = (id, data, callback) => async (dispatch) => {
  try {
    const response = await UPDATE_SERVICE(id, data);
    if (response.status === 200) {
      callback(response.data);
    } else {
      callback(response.response?.data);
    }
  } catch (error) {
    dispatch(serviceFailure(error.response));
  }
};
