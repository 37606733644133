import React from "react";
import waveIcon from "include/Images/wave-blue.png";

function SectionHeading({ heading, subHeading }) {
  return (
    <div className="common_header_wrapper d-flex align-items-center justify-content-center mb-3">
      <div class="common_header d-flex flex-column align-items-center justify-content-center">
        <img src={waveIcon} alt="" />
        <h1 className="text-center">{heading}</h1>
        <p>{subHeading}</p>
      </div>
    </div>
  );
}

export default SectionHeading;
