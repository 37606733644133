import { configureStore, combineReducers } from "@reduxjs/toolkit";
// Import reducer slices
import auth from "store/auth/authSlices";
import service from "store/services/serviceSlices";
import location from "store/locations/locationSlices";
import booking from "store/bookings/bookingsSlices";

// Import other reducer slices as needed

const rootReducer = combineReducers({
  auth,
  service,
  location,
  booking
  // Add other reducers here...
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // Disable serializable check
    }),
});
