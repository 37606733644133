import React, { useState, useEffect, useCallback } from "react";
import {
  Pagination,
  FormControl,
  InputGroup,
  Tooltip,
  OverlayTrigger,
  Alert,
} from "react-bootstrap";
import moment from "moment";
import { connect } from "react-redux";

import { loadBookings } from "store/bookings/bookingsActions";
import SectionHeading from "common/sectionHeading";

const statusMap = {
  pending: "warning",
  completed: "success",
  failed: "error",
};
const BookingsListing = ({ loadBookings, getBookings, selected = [] }) => {
  const pagination = getBookings?.pagination;
  const items = getBookings?.bookings;
  const [loading, setLoading] = useState(true);
  const [keyword, setKeyword] = useState("");
  const [renderCount, setRenderCount] = useState(1);
  const [page, setPage] = useState(1);
  const [params, setParams] = useState({
    sort: "date",
    order: "desc",
  });
  const handleSearchInputChange = (event) => {
    setKeyword(event.target.value);
  };
  const onPageChange = useCallback((value) => {
    setPage(value);
  }, []);

  useEffect(() => {
    if (renderCount > 1) {
      getData();
    }
  }, [page]);

  const getData = () => {
    const payLoad = {
      ...params,
      page,
      keyword,
    };
    loadBookings(payLoad, (res) => {
      setRenderCount(2);
      setLoading(false);
    });
  };
  useEffect(() => {
    const searchData = setTimeout(() => {
      getData();
    }, 300);
    return () => clearTimeout(searchData);
  }, [keyword]);
  return (
    <section class="cart_area pt-5">
      <div class="container px-4">
        <h1 className="text-center page-heading mb-4">Your Bookings</h1>
        <div class="cart_inner">
          <div className="d-flex justify-content-center">
            <Alert variant={"warning"} className="text-center">
              <i
                class="fa-solid fa-circle-info"
                style={{ color: "orange", marginRight: 10 }}
              ></i>
              You need to show the <strong>Token</strong> number of your booking
              at the counter
            </Alert>
          </div>
          <InputGroup
            className=" position-relative  "
            style={{ maxWidth: 300 }}
          >
            <span className=" position-absolute search-icon">
              <i class="fas fa-search"></i>
            </span>
            <FormControl
              className="search-input pe-3 "
              placeholder="Search Bookings"
              value={keyword}
              onChange={handleSearchInputChange}
            />
          </InputGroup>
          <div class="table-responsive custom-table">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Product</th>
                  <th scope="col">Service</th>
                  <th scope="col">Seats</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Token</th>
                  <th scope="col">Visiting Date</th>
                  <th scope="col">Status</th>
                </tr>
              </thead>
              <tbody>
                {items.map((booking, index) => {
                  const isSelected = selected.includes(booking.id);
                  const visitedOn = moment(booking?.visitOn).format("DD/MM/YY");

                  return (
                    <tr key={index}>
                      <td>
                        <div class="media">
                          <div class="d-flex"></div>
                          <div class="media-body">
                            <p className=" fs-6 text-capitalize ">
                              {booking.name}
                              <br />
                              <small className="text-muted">
                                {booking?.mobile}
                                <br />
                                {booking?.nationality}
                              </small>
                            </p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <h5>{booking.service?.name}</h5>
                      </td>
                      <td>
                        <h5>{booking?.seatsRequired}</h5>
                      </td>
                      <td>
                        <h5>₹ {booking?.totalAmount?.toLocaleString()}</h5>
                      </td>
                      <td>
                        <h5>
                          <span
                            className={booking?.tokenId ? "" : "text-muted"}
                          >
                            {booking?.tokenId}
                          </span>
                        </h5>
                      </td>
                      <td>
                        <h5>{visitedOn}</h5>
                      </td>
                      <td>
                        <span
                          className={`badge bg-${statusMap[booking.bookingStatus]
                            }`}
                          style={{
                            pointerEvents: "none",
                            textTransform: "capitalize",
                          }}
                          onClick={(e) => { }}
                        >
                          {booking.bookingStatus}
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <Pagination>
          <Pagination.Prev
            disabled={pagination?.current_page === 1}
            onClick={() => onPageChange(pagination?.current_page - 1)}
          />
          <Pagination.Item>{pagination?.current_page}</Pagination.Item>
          <Pagination.Next
            disabled={
              pagination?.current_page ===
              Math.ceil(pagination?.total_record / pagination?.record_per_page)
            }
            onClick={() => onPageChange(pagination?.current_page + 1)}
          />
        </Pagination>
      </div>
    </section>
  );
};
const mapStateToProps = (state) => ({
  jwt: state.auth.jwt,
  getBookings: state.booking,
});

const mapDispatchToProps = (dispatch) => ({
  loadBookings: (params, callback) => dispatch(loadBookings(params, callback)),
});
export default connect(mapStateToProps, mapDispatchToProps)(BookingsListing);
