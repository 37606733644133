import React from "react";
import { Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const TextInput = ({
  icon,
  type,
  placeholder,
  name,
  maxLength,
  isInvalid,
  onBlur,
  onChange,
  value,
  error,
  readOnly,
  iconClick,
  disabled,
}) => {
  return (
    <>
      <Form.Group controlId={name} className="position-relative">
        <Form.Control
          type={type}
          placeholder={placeholder}
          name={name}
          disabled={disabled}
          maxLength={maxLength}
          isInvalid={isInvalid}
          onBlur={onBlur}
          onChange={onChange}
          value={value}
          readOnly={readOnly ? readOnly : false}
          className="form_control"
        />
        <Form.Label
          className={`position-absolute ${iconClick && "hover"}`}
          style={{ right: 10, top: 10 }}
          onClick={iconClick}
        >
          <i class={icon}></i>
        </Form.Label>
      </Form.Group>
      {error && <Form.Text className="text-danger">{error}</Form.Text>}
    </>
  );
};

export default TextInput;
