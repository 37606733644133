import React from "react";
import DatePicker from "react-datepicker";
import { Form } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const DatePickerComponent = ({
  selected,
  name,
  onChange,
  onBlur,
  dateFormat,
  placeholder,
  error,
  disabled,
}) => {
  return (
    <>
      <Form.Group controlId={name}>
        <DatePicker
          id={name}
          name={name}
          placeholderText={placeholder}
          className={` form-control ${error && "error_styles"}`}
          selected={selected}
          onChange={onChange}
          onBlur={onBlur}
          dateFormat={dateFormat}
          minDate={Date.now()}
          disabled={disabled}
        />
      </Form.Group>
      {error && <Form.Text className="text-danger">{error}</Form.Text>}
    </>
  );
};

export default DatePickerComponent;
