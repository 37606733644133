// userSlice.js
import { createSlice } from "@reduxjs/toolkit";

const locationSlice = createSlice({
  name: "location",
  initialState: {
    locations: [],
    loading: false,
    errors: null,
    pagination: null,
  },
  reducers: {
    locationTriggered: (state, action) => {
      state.loading = true;
    },
    locationsReceived: (state, action) => {
      state.locations = action.payload;
      state.loading = false;
    },
    paginationReceived: (state, action) => {
      state.pagination = action.payload;
      state.loading = false;
    },
    locationFailure: (state, action) => {
      state.errors = action.payload;
      state.loading = false;
    },
  },
});

export const { locationTriggered, locationsReceived, locationFailure, paginationReceived } =
  locationSlice.actions;
export default locationSlice.reducer;
