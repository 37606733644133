import api from "store/api/api";
const token = localStorage.getItem("user-auth-x-token");

//  Get Bookings
export const GET_BOOKINGS = (params) => api.get("/booking", { params });

//  Get Bookings Details
export const GET_BOOKING_DETAILS = (id) => api.get(`/booking/${id}`);

//  Create Bookings
export const CREATE_BOOKING = (data) => api.post(`/booking`, data);

//  Update Bookings
export const UPDATE_BOOKING = (data) => api.put(`/booking/modify`, data);

//  Update Bookings
export const GET_COUNT = (data) => api.get(`/booking/count`, { params: data });

//  Update Bookings Status
export const UPDATE_BOOKING_STATUS = (data) => api.put(`/booking/status`, data);

// Get Vendor on priority
export const GET_VENDOR_PRIORITY = (serviceId, userId) =>
  api.get(`/vendor/${serviceId}/${userId}`);
