import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth'

const firebaseConfig = {
    apiKey: "AIzaSyA325kqFrOJSMLjYkn2DdJirpGs7tA0o0I",
    authDomain: "goawatersports-c23cf.firebaseapp.com",
    projectId: "goawatersports-c23cf",
    storageBucket: "goawatersports-c23cf.appspot.com",
    messagingSenderId: "1043907909534",
    appId: "1:1043907909534:web:9f952481b540548559688f",
    measurementId: "G-1PT8DFRPTS",
    appVerificationDisabledForTesting: true
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app)
// let auth = firebase.auth();
// export { auth, firebase };