import React from "react";
import Lottie from "lottie-react";
import successGif from "include/Images/success.json";
import { Link } from "react-router-dom";

const ConfirmationInfo = () => {
  const isLoggedIn = localStorage.getItem("user-auth-x-token");
  return (
    <section class="order_details py-5">
      <div class="container">
        <div className="d-flex align-items-center flex-column">
          <Lottie
            animationData={successGif}
            loop={false}
            style={{
              maxWidth: "100%",
              width: 200,
            }}
          />
          <h3 class="title_confirmation mt-5">
            Thank you. Your order has been received.
          </h3>
          <p className="mt-4">
            You can go to{" "}
            <Link to={isLoggedIn ? "/profile" : "login"} className="order-link">
              Tracking
            </Link>{" "}
            page to track your orders.
          </p>
        </div>
      </div>
    </section>
  );
};

export default ConfirmationInfo;
