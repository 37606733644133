import Header from "common/header/header";
import TextBanner from "common/textBanner";
import React, { useEffect } from "react";
import LoginForm from "./layouts/loginForm";
import Footer from "common/footer";

const Login = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      <LoginForm />
      <Footer />
    </>
  );
};

export default Login;
