import React, { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

//  Assets
import beach1 from "include/Images/beach/beach1.jpg";
import beach2 from "include/Images/beach/beach2.jpeg";
import beach3 from "include/Images/beach/beach3.jpg";
import beach4 from "include/Images/beach/beach4.jpg";
import beach5 from "include/Images/beach/beach5.jpg";
import beach6 from "include/Images/beach/beach6.jpg";
import beach7 from "include/Images/beach/beach7.jpg";
import beach8 from "include/Images/beach/beach8.jpg";
import beach9 from "include/Images/beach/beach9.jpg";
import beach10 from "include/Images/beach/beach10.jpg";
import beach11 from "include/Images/beach/beach11.jpg";
import beach12 from "include/Images/beach/beach12.jpg";

//  Components
import SectionHeading from "common/sectionHeading";

//  Redux
import { connect } from "react-redux";
import { loadLocations } from "store/locations/locationActions";

function Features({ loadLocations, getLocations }) {
  // const ref = useRef();
  // const { ref } = useParallax({ speed: 10 });
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [keyword, setKeyword] = useState("");
  const [page, setPage] = useState(1);
  const [params, setParams] = useState({
    sort: "name",
    order: "desc",
  });

  useEffect(() => {
    const payLoad = {
      ...params,
      page,
      keyword,
    };
    loadLocations(payLoad, (res) => {
      setLoading(false);
    });
  }, []);

  const getRandomImageUrl = () => {
    const imageUrls = [
      beach1,
      beach2,
      beach3,
      beach4,
      beach5,
      beach6,
      beach7,
      beach8,
      beach9,
      beach10,
      beach11,
      beach12,
    ];
    const randomIndex = Math.floor(Math.random() * imageUrls.length);
    return imageUrls[randomIndex];
  };
  return (
    <section class="features-area section_gap about_container" id="features">
      <div class="container">
        <h1 className=" text-center mb-5 "></h1>
        <SectionHeading
          heading={"Our Destinations"}
          subHeading="Explore Top Tourist Destinations: Your Ultimate Adventure Awaits!"
        />

        <div class="row features-inner">
          {getLocations?.locations.map((location, index) => (
            <div class="col-lg-3 col-sm-6 px-4 mb-5">
              <div class="single-features">
                <div class="f-icon">
                  <img src={getRandomImageUrl(location?.name)} alt="" />
                </div>
                <div
                  class="overlay"
                  onClick={() => {
                    navigate("/shop", { state: location });
                  }}
                >
                  <div className="text-box">
                    <h6 className="mb-2 text-white fs-4 text-capitalize ">
                      {location?.name}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

const mapStateToProps = (state) => ({
  getLocations: state.location,
});

const mapDispatchToProps = (dispatch) => ({
  loadLocations: (params, callback) =>
    dispatch(loadLocations(params, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Features);
