// actions.js
import {
  bookingTriggered,
  bookingsReceived,
  bookingFailure,
  paginationReceived,
  bookingDetailsReceived,
  bookingCountReceived,
  vendorFailure,
  vendorTriggered,
  vendorsReceived,
} from "./bookingsSlices";

import {
  GET_BOOKINGS,
  CREATE_BOOKING,
  // UPDATE_SERVICE,
  UPDATE_BOOKING_STATUS,
  GET_BOOKING_DETAILS,
  GET_COUNT,
  GET_VENDOR_PRIORITY,
} from "./bookingsAPI";

export const loadBookings = (params, callback) => async (dispatch) => {
  try {
    dispatch(bookingTriggered());
    const response = await GET_BOOKINGS(params);
    if (response.status === 200) {
      dispatch(bookingsReceived(response.data.data));
      if (params.page) dispatch(paginationReceived(response.data.pagination));
    } else {
      dispatch(bookingFailure(response.data));
    }
    callback(response.data);
  } catch (error) {
    dispatch(bookingFailure(error.response));
  }
};

export const loadBookingCount = (params, callback) => async (dispatch) => {
  try {
    const response = await GET_COUNT(params);
    if (response.status === 200) {
      dispatch(bookingCountReceived(response.data.data));
    } else {
      dispatch(bookingFailure(response.data));
    }
    callback(response.data);
  } catch (error) {
    dispatch(bookingFailure(error.response));
  }
};

export const loadBookingDetails = (id, callback) => async (dispatch) => {
  try {
    dispatch(bookingTriggered());
    const response = await GET_BOOKING_DETAILS(id);
    if (response.status === 200) {
      dispatch(bookingDetailsReceived(response.data.data));
    } else {
      dispatch(bookingFailure(response.data));
    }
    callback(response.data);
  } catch (error) {
    dispatch(bookingFailure(error.response));
  }
};

export const createBooking = (data, callback) => async (dispatch) => {
  try {
    const response = await CREATE_BOOKING(data);
    if (response.status === 200) {
      callback(response.data);
    } else {
      callback(response.response?.data);
    }
  } catch (error) {
    callback(error?.response.data);
    dispatch(bookingFailure(error.response));
  }
};

export const updateBookingStatus = (data, callback) => async (dispatch) => {
  try {
    const response = await UPDATE_BOOKING_STATUS(data);
    if (response.status === 200) {
      callback(response.data);
    } else {
      callback(response.response?.data);
    }
  } catch (error) {
    dispatch(bookingFailure(error.response));
  }
};

export const loadVendorsOnPriority =
  (serviceId, callback) => async (dispatch) => {
    try {
      dispatch(vendorTriggered());
      const userId = JSON.parse(localStorage.getItem("user"))?._id;
      const response = await GET_VENDOR_PRIORITY(serviceId, userId);
      if (response.status === 200) {
        dispatch(vendorsReceived(response.data.data));
      } else {
        dispatch(vendorFailure(response.data));
      }
      callback(response.data);
    } catch (error) {
      dispatch(vendorFailure(error.response));
    }
  };
