import Button from "common/Button";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { loadBookingDetails } from "store/bookings/bookingsActions";
import { Alert } from "react-bootstrap";

const BookingDetails = ({ getBookings, loadBookingDetails }) => {
  const bookingDetails = JSON.parse(localStorage.getItem("bookingDetails"));
  const invoiceDetails = JSON.parse(localStorage.getItem("invoiceDetails"));
  const bookings = JSON.parse(localStorage.getItem("bookings"));
  const [details, setDetails] = useState(null);
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  useEffect(() => {
    if (bookings !== null) {
      loadBookingDetails(bookings?._id, (res) => {
        // console.log("res", res)
        setDetails(res?.data);
      });
    }
  }, [bookings?._id]);
  return (
    <section class="order_details">
      <div class="container   position-relative">
        <div className="d-flex justify-content-center">
          <Alert variant={"warning"} className="text-center">
            <i
              class="fa-solid fa-circle-info"
              style={{ color: "orange", marginRight: 10 }}
            ></i>
            You need to show the <strong>Token</strong> number of your booking
            at the counter
          </Alert>
        </div>
        <div className="order_value position-relative pt-5" ref={componentRef}>
          {/* <h3 class="title_confirmation">Thank you. Your order has been received.</h3> */}
          <div class="row order_d_inner d-flex flex-row justify-content-between ">
            <div class="invoice_columns">
              <div class="details_item">
                <h4>User Info</h4>
                <ul class="list">
                  <li>
                    <span class="text_bold">Name</span> : {bookingDetails?.name}
                  </li>
                  <li>
                    <span class="text_bold">Contact Number</span> :{" "}
                    {bookingDetails?.mobile}
                  </li>
                  <li>
                    <span class="text_bold">Nationality</span> :{" "}
                    {details?.nationality}
                  </li>
                  <li>
                    <span class="text_bold">Payment method</span> : Online
                    Booking
                  </li>
                </ul>
              </div>
            </div>
            <div class="invoice_columns">
              <div class="details_item">
                <h4>Booking Info</h4>
                <ul class="list">
                  {/* <li><span class="text_bold">Service</span> : {invoiceDetails?.service?.name}</li> */}
                  {/* <li><span class="text_bold">Seats</span> : {bookingDetails?.seatsRequired}</li> */}
                  <li>
                    <span class="text_bold">Date</span> :{" "}
                    {moment(bookingDetails?.visitOn).format("DD/MM/YY")}
                  </li>
                  {/* <li><span class="text_bold">Total</span> : ₹ {bookingDetails?.totalAmount.toLocaleString(undefined, {
                                        maximumFractionDigits: 2,
                                        minimumFractionDigits: 2,
                                    })}</li> */}
                  <li>
                    <span class="text_bold">Booking Number</span> :{" "}
                    {details?.bookingNumber}
                  </li>
                  <li>
                    <span class="text_bold">Token Number</span> :{" "}
                    {details?.tokenId}
                  </li>
                  {/* <li><span class="text_bold">Service</span> : {invoiceDetails?.service?.name}</li> */}
                </ul>
              </div>
            </div>
          </div>
          <div class="order_details_table">
            <div className=" d-flex  justify-content-between align-items-center">
              <h2>Invoice</h2>
            </div>
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">PARTICULAR</th>
                    <th scope="col">QUANTITY</th>
                    <th scope="col">RATE</th>
                    <th scope="col">AMOUNT</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <p>{invoiceDetails?.service?.name}</p>
                    </td>
                    <td>
                      <h5>{invoiceDetails?.seats}</h5>
                    </td>
                    <td>
                      <p>₹ {invoiceDetails?.seatAmount}</p>
                    </td>
                    <td>
                      <p>₹ {invoiceDetails?.baseAmount}</p>
                    </td>
                  </tr>
                  {/* <tr>
                                    <td>
                                        <p>Pixelstore fresh Blackberry</p>
                                    </td>
                                    <td>
                                        <h5>x 02</h5>
                                    </td>
                                    <td>
                                        <p>$720.00</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Pixelstore fresh Blackberry</p>
                                    </td>
                                    <td>
                                        <h5>x 02</h5>
                                    </td>
                                    <td>
                                        <p>$720.00</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h4>Subtotal</h4>
                                    </td>
                                    <td>
                                        <h5></h5>
                                    </td>
                                    <td>
                                        <p>$2160.00</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h4>Shipping</h4>
                                    </td>
                                    <td>
                                        <h5></h5>
                                    </td>
                                    <td>
                                        <p>Flat rate: $50.00</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h4>Total</h4>
                                    </td>
                                    <td>
                                        <h5></h5>
                                    </td>
                                    <td>
                                        <p>$2210.00</p>
                                    </td>
                                </tr> */}
                </tbody>
              </table>
              <div className="d-flex justify-content-end align-items-center mt-5">
                <div className="d-flex">
                  <div className="invoice_details_title">
                    <p>Total Taxable Amount :</p>
                    <p>CGST @{invoiceDetails?.sgstPerc} :</p>
                    <p>SGST @{invoiceDetails?.cgstPerc} :</p>
                    <p className="">Total :</p>
                  </div>
                  <div className="invoice_details_desc">
                    <p>
                      <span class="text_bold">
                        {invoiceDetails?.baseAmount}
                      </span>
                    </p>
                    <p>
                      <span class="text_bold">{invoiceDetails?.sgst}</span>
                    </p>
                    <p>
                      <span class="text_bold">{invoiceDetails?.cgst}</span>
                    </p>
                    <p className="">
                      <span class="text_bold">
                        {invoiceDetails?.totalAmount.toLocaleString(undefined, {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                        })}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Button
          classes={"border-none order_download_btn position-absolute"}
          loading={false}
          text="Print Invoice"
          onClick={(e) => {
            e.preventDefault();
            handlePrint(e);
          }}
        />
      </div>
    </section>
  );
};
const mapStateToProps = (state) => ({
  getBookings: state.booking,
});
const mapDispatchToProps = (dispatch) => ({
  loadBookingDetails: (_id, callback) =>
    dispatch(loadBookingDetails(_id, callback)),
});
export default connect(mapStateToProps, mapDispatchToProps)(BookingDetails);
