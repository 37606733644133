import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import waveIcon from "include/Images/wave-blue.png";

import { loadServices } from "store/services/serviceActions";
import { connect } from "react-redux";
import ServiceCard from "../common/serviceCard";
import SectionHeading from "common/sectionHeading";

const ListingProducts = ({ loadServices, getServices }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [keyword, setKeyword] = useState("");
  const [page, setPage] = useState(1);
  const [params, setParams] = useState({
    sort: "name",
    order: "desc",
  });
  useEffect(() => {
    const payLoad = {
      ...params,
      page,
      keyword,
    };
    loadServices(location?.state?._id, payLoad, (res) => {
      setLoading(false);
    });
  }, [location]);
  const maxSeatCalculator = (array) => {
    let maxTotalSeats = 0;
    // let maxAvailableSeats = 0;

    for (const item of array) {
      const { seats } = item;
      const { totalSeats, availableSeats } = seats;

      if (totalSeats > maxTotalSeats) {
        maxTotalSeats = totalSeats;
      }

      // if (availableSeats > maxAvailableSeats) {
      //     maxAvailableSeats = availableSeats;
      // }
    }

    return maxTotalSeats; // maxAvailableSeats,;
  };
  return (
    <div className="features-area listing_products text-bg">
      <div class="container">
        {getServices?.services?.length > 0 ? (
          <>
            <SectionHeading
              heading={"Services We Provide"}
              subHeading="Dive into a world of water sports at our location and indulge in a variety of aquatic adventures."
            />

            <div class="row features-inner">
              <div class="col-xl-12 col-lg-12 col-md-12">
                <section class="lattest-product-area pb-40 category-list">
                  <div class="row">
                    {getServices?.services?.map((service, index) => (
                      <div class="col-12 col-md-6 col-lg-4" key={index}>
                        <ServiceCard
                          name={service?.name}
                          amount={service?.amount}
                          index={index}
                          maxSeats={maxSeatCalculator(service?.vendors)}
                          onClick={(e) => {
                            if (e) e.preventDefault();
                            navigate("/checkout", {
                              state: {
                                service,
                                loc: location?.state,
                              },
                            });
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </section>
              </div>
            </div>
          </>
        ) : (
          <h1 className=" text-center mb-4 mt-4 ">
            No Services Currently at this Locations{" "}
          </h1>
        )}
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  getServices: state.service,
});

const mapDispatchToProps = (dispatch) => ({
  loadServices: (_id, params, callback) =>
    dispatch(loadServices(_id, params, callback)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ListingProducts);
