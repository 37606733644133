import React from "react";

const Details = () => {
  return (
    <div className="container position-relative contact_wrapper ">
      <div className="contact_box">
        <h1 className="fw-normal mt-3 mb-3">Connect with Us</h1>
        <div className="row">
          <div class="col-lg-12">
            <div class="contact_info">
              <div class="info_item">
                <i class="lnr lnr-home"></i>
                <h6>Anjuna, Goa</h6>
                <p>H.no 87, Anjuna, Goa</p>
              </div>
              <div class="info_item">
                <i class="lnr lnr-phone-handset"></i>
                <h6>
                  <a href="#">7020858404 </a>
                </h6>
                <p>Mon to Fri 9am to 6 pm</p>
              </div>
              <div class="info_item">
                <i class="lnr lnr-envelope"></i>
                <h6>
                  <a href="#">goawatersportsassociation@gmail.com</a>
                </h6>
                <p>Send us your query anytime!</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Details;
