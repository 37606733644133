import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import CryptoJS from "crypto-js";

//  Stripe
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

//  Components
import Footer from "common/footer";
import Header from "common/header/header";
import React from "react";
import PaymentForm from "./layouts/paymentForm";

const Payment = () => {
  const [stripePublicKey, setPublicKey] = useState("");
  const navigate = useNavigate();
  const locationData = useLocation();
  const loc = locationData?.state?.loc;
  const clientSecret = locationData?.state?.res?.clientSecret;
  useEffect(() => {
    const pubKey = decrypt(loc?.stripePublicKey);
    setPublicKey(pubKey);
  }, [loc?.stripePublicKey]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  /**
   *
   * Stripe
   *
   */
  // Make sure to call loadStripe outside of a component’s render to avoid
  // recreating the Stripe object on every render.
  // This is a public sample test API key.
  // Don’t submit any personally identifiable information in requests made with this key.
  // Sign in to see your own test API key embedded in code samples.
  const decrypt = (data) => {
    const bytes = CryptoJS.AES.decrypt(
      data,
      process.env.REACT_APP_ENCRYPTION_KEY
    );
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    console.log("decrypt:", decryptedData);
    return decryptedData;
  };
  const stripePromise = loadStripe(stripePublicKey);

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  if (!clientSecret) return navigate("/");
  return (
    <>
      <Header />
      {clientSecret && stripePublicKey && (
        <Elements options={options} stripe={stripePromise}>
          <section class="checkout_area  payment_area">
            <PaymentForm />
          </section>
        </Elements>
      )}
      <Footer />
    </>
  );
};

export default Payment;
