import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});
api.interceptors.request.use((config) => {
  config.params = config.params || {};

  // const isBrowser = process.browser;

  const token = localStorage.getItem("user-auth-x-token");
  if (token) {
    if (token) config.headers["jwtToken"] = `${token}`;
  }

  return config;
});
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 401) {
      window.location.replace("/login");
      localStorage.clear();
    }
    return error;
  }
);
export default api;