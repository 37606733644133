import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import OtpInput from 'react-otp-input';


const CustomModal = (props) => {
    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            className='custom_modal '
        >
            <Modal.Header className='custom_header' closeButton>
                <h4>Enter Your OTP</h4>
            </Modal.Header>
            <Modal.Body className='custom_body'>
                <OtpInput
                    containerStyle={
                        {
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }
                    }
                    inputStyle="inputStyle"
                    value={props.otp}
                    onChange={props.setOtp}
                    numInputs={6}
                    renderSeparator={<span></span>}
                    renderInput={(props) => <input {...props}
                    />}
                />
            </Modal.Body>
            <Modal.Footer className=' d-flex  justify-content-end custom_footer '>
                {/* <Button className='primary-btn verify_btn close_btn' onClick={props.onHide}>Close</Button> */}
                <Button className='primary-btn verify_btn' onClick={props.onOTPVerify}>Verify OTP</Button>
            </Modal.Footer>
        </Modal>
    );
}
export default CustomModal;